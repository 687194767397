/*
 TinyMCE dependencies and configuration
 */

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// A theme is also required
import 'tinymce/models/dom/model';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview/plugin.min';
import 'tinymce/plugins/importcss/plugin.min';
import 'tinymce/plugins/searchreplace/plugin.min';
import 'tinymce/plugins/autolink/plugin.min';
import 'tinymce/plugins/directionality/plugin.min';
import 'tinymce/plugins/code/plugin.min';
import 'tinymce/plugins/visualblocks/plugin.min';
import 'tinymce/plugins/visualchars/plugin.min';
import 'tinymce/plugins/fullscreen/plugin.min';
import 'tinymce/plugins/image/plugin.min';
import 'tinymce/plugins/link/plugin.min';
import 'tinymce/plugins/media/plugin.min';
import 'tinymce/plugins/codesample/plugin.min';
import 'tinymce/plugins/table/plugin.min';
import 'tinymce/plugins/charmap/plugin.min';
import 'tinymce/plugins/pagebreak/plugin.min';
import 'tinymce/plugins/nonbreaking/plugin.min';
import 'tinymce/plugins/anchor/plugin.min';
import 'tinymce/plugins/insertdatetime/plugin.min';
import 'tinymce/plugins/advlist/plugin.min';
import 'tinymce/plugins/lists/plugin.min';
import 'tinymce/plugins/wordcount/plugin.min';
import 'tinymce/plugins/quickbars/plugin.min';
import 'tinymce/plugins/help/plugin.min'
import 'tinymce/plugins/autoresize/plugin.min';

window.tinymceplugins = 'autoresize preview importcss searchreplace autolink directionality code visualblocks help fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap quickbars';
window.tinymcetoolbar = 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen preview print | insertfile image media link code visualblocks help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol';
window.tinymcedefaultcontentstyle = "body { font-size: 12pt; font-family: Arial; }";
window.tinymceeditorheight = 220;
window.tinymcemenubar = false;
window.tinymcetoolbarmode = "sliding";
window.tinymcebranding = false;
window.tinymcelanguage = 'de';
window.tinymceimagetitle = true;
window.tinymceautomaticuploads = true;

