// assets/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.

// import "@mdi/font/scss/materialdesignicons.scss"
import "font-awesome/css/font-awesome.min.css"
import "sweetalert2/dist/sweetalert2.css"

import "core-js/stable"
import "regenerator-runtime/runtime"
import "jquery-ui"
import "jquery-contextmenu"

/* include Sentry */
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

global.jQuery = require("jquery")
global.Sentry = Sentry
global.SentryIntegrations = Integrations

/* Include jQuery UI components selectivley to keep the JS small */
require("jquery-ui/ui/widgets/autocomplete")
require("jquery-ui/ui/widgets/draggable")
require("jquery-ui/ui/widgets/resizable")
require("jquery-ui/ui/widgets/accordion")
require("jquery-ui/ui/widgets/button")
require("jquery-ui/ui/widgets/menu")

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/accordion.css';
import 'jquery-ui/themes/base/button.css';
import 'jquery-ui/themes/base/menu.css';

const $ = jQuery
window.$ = $
window.jQuery = $

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

window.JSZip = require('jszip')

//for reports module
import 'bootstrap-daterangepicker/daterangepicker.css';
require('bootstrap-daterangepicker/daterangepicker');

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min.js')

require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-select');
require('datatables.net-bs5/js/dataTables.bootstrap5.min');
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
require('datatables.net-colreorder-bs5')
import 'datatables.net-colreorder-bs5/css/colReorder.bootstrap5.min.css';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs5';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/dataTables.buttons';

// TinyMCE dependencies and configuration
require('./components/tinymce');

import i18next from 'i18next';
i18next.init({
    supportedLngs: ["de", "en"],
    lng: 'de',
    fallbackLng: 'en',
    resources: {
        de: {
            translation: {
            }
        },
        en: {
            translation: {
            }
        }
    }
});
window.i18next = i18next;

console.log("JS: Header libraries loaded.")
